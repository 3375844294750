import { createEpmConfigRoutes } from '@pats/epm-config-react-app/manifest'
import type { AuthState, FeatureInfo } from '@sparky/framework'
import { getEpmConfigRootKey, isEpmConfigAvail, isFeatureEnabled } from './feature'
import { i18n } from './i18n'

export function manifest() {
	return async ({ auth }: { auth: AuthState }) => {
		const intl = i18n(navigator.language ?? 'en')
		const isPanoramaTenant = !!auth.instances.get('prisma_access_edition_onprem')
		const epmConfigRoot = getEpmConfigRootKey(isPanoramaTenant)
		const epmConfigRootPath = `/${epmConfigRoot}`

		const epmConfigRoutes = createEpmConfigRoutes(epmConfigRootPath)

		return [
			{
				key: 'manage',
				path: '/manage',
				mergeNav: true,
				children: [
					{
						key: 'epm-inventory',
						path: '/manage/epm/inventory',
						title: intl.formatMessage({ defaultMessage: 'Prisma Access Agent', id: 'xtExMx' }),
						contentClassName: 'panwds-tw3',
						avail({ featuresInfo }: { featuresInfo: FeatureInfo[] }) {
							return isFeatureEnabled(featuresInfo, 'ztna_agent')
						},
						component: () => import('../main').then(m => m.activate())
					}
				]
			},
			{
				key: epmConfigRoot,
				path: epmConfigRootPath,
				mergeNav: true,
				children: [
					{
						key: epmConfigRoutes.default.key,
						path: epmConfigRoutes.default.path,
						title: intl.formatMessage({ defaultMessage: 'Prisma Access Agent', id: 'xtExMx' }),
						// @TODO: remove this once the new nav code from sparky is available
						callAvail: true,
						avail() {
							const isAvail = isEpmConfigAvail()
							return isAvail // isPanoramaTenant
						},
						contentClassName: 'panwds-tw3',
						component: () => import('../epm-config').then(m => m.activate(epmConfigRootPath)),
						children: [
							{
								key: epmConfigRoutes.setup.key,
								path: epmConfigRoutes.setup.path,
								title: intl.formatMessage({ defaultMessage: 'Setup', id: 'dvr19k' })
							}
						]
					}
				]
			}
		]
	}
}
