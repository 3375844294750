/**
 * @param baseUrl e.g. `/workflows` or `/settings`
 */
export function createEpmConfigRoutes(baseUrl: string) {
	return {
		default: {
			key: `epm-config`,
			path: `${baseUrl}/epm-config`
		},
		setup: {
			key: 'setup',
			path: `${baseUrl}/epm-config/setup`
		},

		setup_infrastructure: {
			key: 'infrastructure',
			path: `${baseUrl}/epm-config/setup/infra-setting`
		},
		setup_infrastructure_edit: {
			key: 'infrastructure-edit',
			path: `${baseUrl}/epm-config/infrastructure/edit`
		},
		ssaa_global_app_settings: {
			key: 'global_app_settings',
			path: `${baseUrl}/epm-config/global_app_settings`
		},
		agent_config: {
			key: 'agent-config',
			path: `${baseUrl}/epm-config/setup/agent`
		}
	}
}
